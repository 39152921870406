import React from "react";


import { Typography, Box, Divider, Button } from "@mui/material"
import PageContents from "../../components/PageContents";
import { createTheme } from '@mui/material/styles';
import CareersPage from "pages/CareersPage";
import ContactUsPage from "pages/ContactPage";
import ProductsPage from "pages/ServicesPage";
import SupportPage from "pages/Suggestions";
import Env from 'env';
import theme_ from "theme/Theme";

import { HashRouter } from 'react-router-dom';

import { Link } from 'react-scroll'

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AboutUsPage from "pages/AboutUsPage";
import PartnersPage from "../PartnersPage";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EastIcon from '@mui/icons-material/East';
import TripOriginIcon from '@mui/icons-material/TripOrigin';

import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'

const ImageComponent = muiStyled('img')({});

const theme = createTheme({
    typography: {
        fontFamily: 'Inter',

    }
});

export function selectedLang(currentLang: string) {
    if (currentLang === 'am') {
        theme_.fontType = 'monospace';
        theme_.supportPageTitleWidth = '80%';
        theme_.aboutUsImageMarginTop = 60
    } else {
        theme_.fontType = 'Inter';
        theme_.supportPageTitleWidth = '100%'
        theme_.aboutUsImageMarginTop = 40

    }
}

export function BackToTopButton() {


    const [backToTopButton, setBackToTopButton] = React.useState(false);



    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return <>

        {backToTopButton && (
            //     <Button  onClick={scrollUp} >
            //     Delete
            //   </Button>
            <Button style={{
                position: 'fixed',
                bottom: '50px',
                right: '50px',
                // height: '3px',
                // width: '9px',
                // fontSize: '80px',
                color: 'transparent',
                // border: '1px solid transparent',
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',


                // backgroundColor: 'transparent',
                // boxShadow: '0 0 0 3pt #fd8f20',
                // borderRadius: '50%',
            }}
                onClick={scrollUp}
            ><ArrowUpwardIcon sx={{ fontSize: 70, marginTop: -5, color: theme_.themeColor, '@media screen and (max-width: 1400px)': { fontSize: 60 } }} /></Button>
        )}

    </>
}


var items = [
    {
        name: "1",
        description: "Probably the most random thing you have ever seen!"
    },
    {
        name: "2",
        description: "Hello World!"
    }
]

function Item(props) {
    return <>

        <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '975px', marginLeft: -0.0645 }} src={`images/law/background-${props.item.name}.jpg`}></ImageComponent>

    </>
    // <Paper>
    //     <h2>{props.item.name}</h2>
    //     <p>{props.item.description}</p>

    //     <Button className="CheckButton">
    //         Check it out!
    //     </Button>
    // </Paper>
    // )
}

export default function HomePage() {

    const [t] = useTranslation();
    let [carouselImgName, setCarouselImgName] = React.useState(0)
    // const [mousedOver, setMousedOver] = React.useState(false);

    // const imagesQuantity = 3
    // let timer;


    // timer = setInterval(() => {
    //     carouselImgName++;
    //     if (carouselImgName > imagesQuantity) {
    //         carouselImgName = 1;
    //         clearInterval(timer)
    //     }
    //     if (carouselImgName < 1) {
    //         carouselImgName = imagesQuantity;
    //         clearInterval(timer)
    //     }
    //     setCarouselImgName(carouselImgName);
    // }, 10000)

    // const updateCount = () => {
    //     timer = setInterval(() => {
    //         carouselImgName++;
    //         if (carouselImgName > imagesQuantity) {
    //             carouselImgName = 1;
    //         }
    //         if (carouselImgName < 1) {
    //             carouselImgName = imagesQuantity;
    //         }
    //         setCarouselImgName((prevCount) => prevCount + 1);
    //     }, 3000);

    //     if (carouselImgName === 3) clearInterval(timer);
    // };

    // const origCount = () => {
    //     clearInterval(timer);
    //     setCarouselImgName((count) => 0);
    // };

    // React.useEffect(() => {
    //     // set an interval timer if we are currently moused over
    //     if (mousedOver) {
    //         if (carouselImgName === 2) {
    //             setCarouselImgName(1);
    //             return;
    //         }
    //         const timerId = setInterval(() => setCarouselImgName(old => old + 1), 1000);
    //         return () => clearInterval(timerId);
    //         // const timer = setInterval(() => {
    //         //     // carouselImgName++;
    //         //     // if (carouselImgName > imagesQuantity) {
    //         //     //     carouselImgName = 1;
    //         //     //     return;
    //         //     // }
    //         //     // if (carouselImgName < 1) {
    //         //     //     carouselImgName = imagesQuantity;
    //         //     //     return;
    //         //     // }
    //         //     // cycle prevCount using mod instead of checking for hard-coded length
    //         //     setCarouselImgName((prevCount) => (prevCount + 1) % 4);
    //         // }, 1000);
    //         // // automatically clear timer the next time this effect is fired or
    //         // // the component is unmounted
    //         // return () => clearInterval(timer);
    //     } else {
    //         // otherwise (not moused over), reset the counter
    //         setCarouselImgName(0);
    //         return () => clearInterval(timer);

    //     }
    //     // the dependency on mousedOver means that this effect is fired
    //     // every time mousedOver changes
    // }, [mousedOver]);



    const [images, setImages] = React.useState(
        Env.isMobile ? [
            4, 7, 8, 9, 10
        ] : [1, 2, 3, 6]
    )
    //   const [index, setIndex] = React.useState(0)

    React.useEffect(() => {
        if (images.length) {
            const timeoutId = setTimeout(() => {
                setCarouselImgName((carouselImgName + 1) % images.length)
            }, 3000)

            return () => clearTimeout(timeoutId)
        }

        return () => { }
    }, [carouselImgName])



    if (Env.isMobile) {
        return <PageContents>

            <>
                <div id="home" >
                    {/* <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: 650 }} src={`images/law/background-${images[carouselImgName]}.jpg`}></ImageComponent> */}
                    <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: 1550 }} src={`images/law/background-${images[carouselImgName]}.jpg`}></ImageComponent>


                    <Button sx={{ top: 500, left: 20 }} onClick={() => {
                        carouselImgName--
                        if (carouselImgName < 0) {
                            carouselImgName = images.length - 1;
                        }
                        // console.log("carouselImgName",carouselImgName)
                        setCarouselImgName(carouselImgName)
                    }}>
                        <ArrowBackIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                    </Button>

                    <Button sx={{ top: 500, float: "right", right: 20 }} onClick={() => {
                        carouselImgName++;
                        if (carouselImgName > images.length - 1) {
                            carouselImgName = 0;
                        }
                        setCarouselImgName(carouselImgName)

                    }}>
                        <ArrowForwardIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                    </Button>


                    {/*
                <Button sx={{top: 700, float: "right" }} onClick={() => {
                    carouselImgName++;
                    if (carouselImgName > images.length -1) {
                        carouselImgName = 0;
                    }
                    setCarouselImgName(carouselImgName)

                }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button>

                <Button sx={{top: 700, float: "right" }} onClick={() => {
                    carouselImgName--
                    if (carouselImgName < 0) {
                        carouselImgName = images.length - 1 ;
                    }
                    // console.log("carouselImgName",carouselImgName)
                    setCarouselImgName(carouselImgName)
                }}>
                    <ArrowBackIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button> */}



                    {/* <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '900px'  }} src="images/law/background-1.jpg"></ImageComponent> */}
                    {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 18, marginTop: 0 }}>{t('PROJECTION, MANUFACTURING')}</Typography> */}
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 25, marginTop: 0, fontWeight: 'bold' }}>{t('ԻՐԱՎԱԲԱՆԱԿԱՆ ԵՎ ')}</Typography>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 25, marginTop: 0, fontWeight: 'bold' }}>{t('ՓԱՍՏԱԲԱՆԱԿԱՆ ԾԱՌԱՅՈԻԹՅՈՒՆՆԵՐ')}</Typography>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 18, marginTop: 1 }}>{t('AND TESTING')}</Typography> */}
                    <Divider sx={{ width: '520px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 8, marginTop: 1 }}></Divider>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 50 }}>{t('WE ARE')}</Typography> */}
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 40, fontWeight: 'bold' }}>{t('Տեսնել անարդարությունը')}</Typography>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 50 }}>{t('MORE THEN')}</Typography> */}
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 40, fontWeight: 'bold' }}>{t('և լռել, նշանակում է')}</Typography>
                    {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 50 }}>{t('IT COMPANY')} </Typography> */}
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 8, fontSize: 40, fontWeight: 'bold' }}>{t('անձամբ մասնակցել դրան')} </Typography>
                </div>


                <div id="about" >
                    <AboutUsPage />

                </div>

                <div id="products">
                    <ProductsPage />


                </div>

                <div id="suggestions">
                    <SupportPage />


                </div>

                {/* <div id="partners">
                    <PartnersPage />

                </div> */}

                <div id="contact">
                    <ContactUsPage />

                </div>

                {BackToTopButton()}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50, backgroundColor: '#D36A00', marginTop: 10, }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 18 }}>Copyright ©2022 All rights reserved | kalashyan_law_firm.com</Typography>

                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50, backgroundColor: '#2f0707', marginTop: 10, }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 18 }}>Copyright ©2024 All rights reserved | kalashyanlawfirm.com</Typography>
                </Box>
            </>
            {/* 
            <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '150%' }} src="images/background-image.png"></ImageComponent>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 18, marginTop: 20 }}>PROJECTION, MANUFACTURING</Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 18, marginTop: 1 }}>AND TESTING</Typography>
            <Divider sx={{ width: '300px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 5, marginTop: 1 }}></Divider>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>WE ARE </Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>MORE THEN </Typography>
            <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 5, fontSize: 50 }}>IT COMPANY </Typography> */}
            {/* 
            <AboutUsPage />
            <ProductsPage />
            <SupportPage />
            <ContactUsPage /> */}
        </PageContents>

    }

    return <PageContents>

        <>
            <div id="home"
            // onMouseOver={() => setMousedOver(true)}
            // onMouseOut={() => setMousedOver(false)}
            >
                {/* <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '975px', marginLeft: -0.0645 }} src="images/background-image.jpg"></ImageComponent> */}
                <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -170, width: '100%', height: 1100, marginLeft: -0.0645, '@media screen and (max-width: 1400px)': { height: 650 } }} src={`images/law/background-${images[carouselImgName]}.jpg`}></ImageComponent>
                <Button sx={{ top: 230, ml: 1,'@media screen and (max-width: 1400px)': { top: 150 } }} onClick={() => {
                    carouselImgName--
                    if (carouselImgName < 0) {
                        carouselImgName = images.length - 1;
                    }
                    // console.log("carouselImgName",carouselImgName)
                    setCarouselImgName(carouselImgName)
                }}>
                    <ArrowBackIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button>

                <Button sx={{ top: 230, float: "right", mr: 1,'@media screen and (max-width: 1400px)': { top: 150 } }} onClick={() => {
                    carouselImgName++;
                    if (carouselImgName > images.length - 1) {
                        carouselImgName = 0;
                    }
                    setCarouselImgName(carouselImgName)

                }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button>






                {/* // close */}

                {/*
                <Button sx={{top: 700, float: "right" }} onClick={() => {
                    carouselImgName++;
                    if (carouselImgName > images.length -1) {
                        carouselImgName = 0;
                    }
                    setCarouselImgName(carouselImgName)

                }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button>

                <Button sx={{top: 700, float: "right" }} onClick={() => {
                    carouselImgName--
                    if (carouselImgName < 0) {
                        carouselImgName = images.length - 1 ;
                    }
                    // console.log("carouselImgName",carouselImgName)
                    setCarouselImgName(carouselImgName)
                }}>
                    <ArrowBackIosIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button> */}






                {/* //other version arrows */}

                {/* <Button sx={{ top: 700, float: "right" }} onClick={() => {
                    carouselImgName++;
                    if (carouselImgName > images.length - 1) {
                        carouselImgName = 0;
                    }
                    setCarouselImgName(carouselImgName)

                }}>
                    <EastIcon sx={{ fontSize: 50, color: theme_.themeColor }} />
                </Button>

                <Button sx={{ top: 700, float: "right" }} onClick={() => {
                    carouselImgName--
                    if (carouselImgName < 0) {
                        carouselImgName = images.length - 1;
                    }
                    // console.log("carouselImgName",carouselImgName)
                    setCarouselImgName(carouselImgName)
                }}>
                    <EastIcon sx={{ fontSize: 50, color: theme_.themeColor, transform: `rotate(180deg)` }} />
                </Button> */}


                {/* <Carousel sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%', height: '975px', marginLeft: -0.0645 }}>
                    {
                        items.map((item, i) => <Item key={i} item={item} />)
                    }
                </Carousel> */}

                {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 20, marginTop: 20 }}>{t('PERSONAL INJURY. CRIMINAL DEFENCE. FAMILY LAW.')}</Typography> */}
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 23, marginTop: 20, fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 20, mt: 0 } }}>{t('ԻՐԱՎԱԲԱՆԱԿԱՆ ԵՎ ՓԱՍՏԱԲԱՆԱԿԱՆ ԾԱՌԱՅՈԻԹՅՈՒՆՆԵՐ')}</Typography>
                <Divider sx={{ width: '730px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 15, marginTop: 1, '@media screen and (max-width: 1400px)': { width: '650px' } }}></Divider>
                {/* Տեսնել անարդարությունը և լռել, նշանակում է անձամբ մասնակցել դրան։ */}
                {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 80 }}>{t('Ամեն նոր ')}</Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 80, marginTop: -4 }}>{t('ժամանակ')} </Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 80, marginTop: -4 }}>{t('տալիս է իր օրենքը')} </Typography> */}

                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 60, '@media screen and (max-width: 1400px)': { fontSize: 55 } }}>{t('Տեսնել անարդարությունը')}</Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 60, marginTop: -2, '@media screen and (max-width: 1400px)': { fontSize: 55 } }}>{t('և լռել, նշանակում է')} </Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 60, marginTop: -2, '@media screen and (max-width: 1400px)': { fontSize: 55 } }}>{t('անձամբ մասնակցել դրան')} </Typography>

                {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 20, marginTop: 20 }}>{t('PERSONAL INJURY. CRIMINAL DEFENCE. FAMILY LAW.')}</Typography>
                <Divider sx={{ width: '550px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 15, marginTop: 1 }}></Divider>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90 }}>{t('Every New')}</Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90, marginTop: -4 }}>{t('Time Will')} </Typography>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', marginLeft: 15, fontSize: 90, marginTop: -4 }}>{t('Give its Law')} </Typography> */}
            </div>


            <div id="about" >
                <AboutUsPage />

            </div>

            <div id="products">
                <ProductsPage />


            </div>

            <div id="suggestions">
                <SupportPage />


            </div>

            {/* <div id="partners">
                <PartnersPage />

            </div> */}

            <div id="contact">
                <ContactUsPage />

            </div>


            {BackToTopButton()}


            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50, backgroundColor: '#2f0707', marginTop: 14, }}>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 18, '@media screen and (max-width: 1400px)': { fontSize: 13 } }}>Copyright ©2024 All rights reserved | kalashyanlawfirm.com</Typography>

            </Box>
        </>
    </PageContents>

    // return <PageContents>


    //     <ImageComponent sx={{ position: 'absolute', zIndex: -1, top: -180, width: '100%' }} src="images/background-image.png"></ImageComponent>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 20, marginTop: 20 }}>PROJECTION, MANUFACTURING AND TESTING</Typography>
    //     <Divider sx={{ width: '500px', borderBottomWidth: 3, backgroundColor: 'white', marginLeft: 15, marginTop: 1 }}></Divider>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>WE ARE </Typography>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>MORE THEN </Typography>
    //     <Typography sx={{ fontFamily: 'Inter', color: 'white', marginLeft: 15, fontSize: 90 }}>IT COMPANY </Typography>



    //     <AboutUsPage />
    //     <ProductsPage />
    //     <SupportPage />
    //     {/* <CareersPage/> */}
    //     <ContactUsPage />
    // </PageContents>
}
