
// import * as Form from 'components/Form';
import { PageHeaderTheme } from './ThemeElements';


// export const dialogBorderStyle = '1px solid #91cbd8';
export const dialogBorderStyle = '1px solid #a38646';
export class ThemeInstance {

   fontType = 'Inter';
   supportPageTitleWidth = '100%'
   // aboutUsImageMarginTop = 40
   aboutUsImageMarginTop = 4

   // themeColor = '#fd8f20'
   // themeColor = 'red'
   themeColor = '#c30207'
   
   // themeColor = '#2f0707'

   partnersLogoWidthDesktop = 200;
   partnersLogoHeightDesktop = 120;
};