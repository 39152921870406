import React from "react";
import Env from 'env'

import { Box, Divider, Typography } from "@mui/material";

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";
import CircleIcon from '@mui/icons-material/Circle';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const ImageComponent = muiStyled('img')({});



export default function SupportPage() {

    const [t] = useTranslation();

    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', height: 1600, backgroundColor: '#2f0707', marginTop: 35 }}>
                {/* 
                <Box sx={{ display: 'grid' }}>

                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 35, marginTop: 3, width: theme_.supportPageTitleWidth, marginRight: 'auto', marginLeft: 'auto' }}>{t('ՀԱՏՈՒԿ ԱՌԱՋԱՐԿՆԵՐ')}</Typography>
                    <Divider sx={{ width: '600px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider>

                </Box> */}
                <Box sx={{ display: 'grid', background: '#22242e', height: 110 }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', textAlign: 'center', fontSize: 35, marginTop: 4 }}>{t('ՀԱՏՈՒԿ ԱՌԱՋԱՐԿՆԵՐ')}</Typography>
                    {/* <Divider sx={{ width: '1000px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider> */}
                </Box>
                {/* <PlayArrowIcon sx={{ color: '#22242e', transform: `rotate(90deg)`, fontSize: 130, mt: -6, textAlign: "center", width: '100%' }} /> */}
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <PlayArrowIcon sx={{ color: '#22242e', transform: `rotate(90deg)`, fontSize: 130, mt: -6, textAlign: "center", }} />
                </Box>
                <Box sx={{ mt: 10 }}>
                    <ImageComponent sx={{ width: 400, mt: -5, ml: 2 }} src="images/law/suggestions-1.jpeg"></ImageComponent>
                    <Box sx={{ textAlign: 'center', width: '95%', ml: 5, mt: 7 }}>
                        {/* <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 23, marginTop: 3, ml: 20 }}>{t('Կորպորատիվ սպասարկում՝ ամսավճարի սկզբունքով')}</Typography> */}
                        {/* <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 16, marginTop: 3, ml: 20 }}>{t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`)}</Typography> */}

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Կորպորատիվ սպասարկում՝ ամսավճարի սկզբունքով')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Խորհրդատվության տրամադրում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Պայմանագրերի կազմում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Գործարքների իրավաբանական ուղեկցում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Ներկայացուցչություն')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Ներկայացուցչություն դատարանում')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Գումարի բռնագանձում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Աշխատանքային վեճեր')}
                            </Typography>
                        </Box>



                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('ՊԵԿ ներկայացուցչություն')}
                            </Typography>
                        </Box>



                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('ՊԵԿ-ի գործերով խորհրդատվություն ')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Վարչական ակտերի բողոքարկում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Դիմումների և հայցադիմումների կազմում')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Դատական ակտերի բողոքարկում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' }}>
                                {t('Կազմակերպությունների լուծարում')}
                            </Typography>
                        </Box>

                    </Box>
                </Box>




                <Box sx={{ display: 'flex', float: "right", mt: 5, mr: 3 }}>
                    {/* <Box sx={{ textAlign: 'center', width: 900 }}>
                        <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 23, marginTop: 3 }}>{t('Խորհրդատվության տրամադրում')}</Typography>
                        <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 16, marginTop: 3, mr: 10 }}>{t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`)}</Typography>
                    </Box> */}
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: 400, '@media screen and (max-width: 1600px)': { width: 100 } }} src="images/law/about.png"></ImageComponent> */}
                    <ImageComponent sx={{ width: 400 }} src="images/law/suggestions-2.jpeg"></ImageComponent>
                </Box>

            </Box>

        </>
    }


    return <>

        <Box sx={{ width: '100%', height: 1100, marginTop: 45, backgroundColor: '#2f0707', '@media screen and (max-width: 1400px)': { height: 1000 } }}>

            <Box sx={{ display: 'grid', background: '#22242e', height: 110 ,'@media screen and (max-width: 1400px)': { height: 80 }}}>
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', textAlign: 'center', fontSize: 35, marginTop: 4, '@media screen and (max-width: 1400px)': { fontSize: 27, mt: 2 } }}>{t('ՀԱՏՈՒԿ ԱՌԱՋԱՐԿՆԵՐ')}</Typography>
                {/* <Divider sx={{ width: '1000px', borderBottomWidth: 5, backgroundColor: '#fd8f20', justifySelf: 'center' }}></Divider> */}
            </Box>
            {/* <PlayArrowIcon sx={{ color: '#22242e', transform: `rotate(90deg)`, fontSize: 130, mt: -6, textAlign: "center", width: '100%' }} /> */}
            <Box sx={{ width: '100%', textAlign: 'center' }}>
                <PlayArrowIcon sx={{ color: '#22242e', transform: `rotate(90deg)`, fontSize: 130, mt: -6, textAlign: "center", '@media screen and (max-width: 1400px)': { fontSize: 115,}}} />
            </Box>
            <Box sx={{ mt: 7 }}>


                <Box sx={{ display: 'flex', ml: 10,'@media screen and (max-width: 1400px)': { mt: -5 } }}>
                    <ImageComponent sx={{ width: 400, height: 280, '@media screen and (max-width: 1600px)': { width: 300, height: 200, } }} src="images/law/suggestions-1.jpeg"></ImageComponent>
                    <Box sx={{ width: 900, ml: 15, mt: 10, '@media screen and (max-width: 1600px)': { ml: 5 }, '@media screen and (max-width: 1680px)': { ml: 5 } }}>
                        {/* <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 23, marginTop: 3, ml: 20 }}>{t('Կորպորատիվ սպասարկում՝ ամսավճարի սկզբունքով')}</Typography> */}
                        {/* <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 16, marginTop: 3, ml: 20 }}>{t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`)}</Typography> */}

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('Կորպորատիվ սպասարկում՝ ամսավճարի սկզբունքով')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('Խորհրդատվության տրամադրում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Պայմանագրերի կազմում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Գործարքների իրավաբանական ուղեկցում')}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Ներկայացուցչություն')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Ներկայացուցչություն դատարանում')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('Գումարի բռնագանձում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Աշխատանքային վեճեր')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('ՊԵԿ ներկայացուցչություն')}
                            </Typography>
                        </Box>



                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('ՊԵԿ-ի գործերով խորհրդատվություն ')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('Վարչական ակտերի բողոքարկում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Դիմումների և հայցադիմումների կազմում')}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af' , '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 }}}>
                                {t('Դատական ակտերի բողոքարկում')}
                            </Typography>
                        </Box>


                        <Box sx={{ display: 'flex', alignItems: 'start', mb: 1.5 }}>
                            <CircleIcon sx={{ fontSize: 'small', color: '#b2b1af' }} />
                            <Typography sx={{ ml: 1, mt: -1.2, fontSize: 'x-large', color: '#b2b1af', '@media screen and (max-width: 1400px)': { fontSize: 'large', mt: -0.7 } }}>
                                {t('Կազմակերպությունների լուծարում')}
                            </Typography>
                        </Box>

                    </Box>
                </Box>




                <Box sx={{ display: 'flex', float: "right", mt: -26, mr: 10, '@media screen and (max-width: 1200px)': { ml: 10, float: 'left' } }}>
                    {/* <Box sx={{ textAlign: 'center', width: 900 }}>
                        <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 23, marginTop: 3 }}>{t('Խորհրդատվության տրամադրում')}</Typography>
                        <Typography sx={{ fontFamily: theme_.fontType, color: '#b2b1af', textAlign: 'start', fontSize: 16, marginTop: 3, mr: 10 }}>{t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`)}</Typography>
                    </Box> */}
                    {/* <ImageComponent sx={{ justifySelf: 'center', width: 400, '@media screen and (max-width: 1600px)': { width: 100 } }} src="images/law/about.png"></ImageComponent> */}
                    <ImageComponent sx={{ justifySelf: 'center', width: 400, height: 280, '@media screen and (max-width: 1600px)': { width: 300, height: 200, mt: 10 } }} src="images/law/suggestions-2.jpeg"></ImageComponent>
                </Box>





            </Box>


        </Box>

    </>

}

