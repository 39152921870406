import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Divider } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Env from 'env'
import { styled as muiStyled } from '@mui/system';

import theme_ from 'theme/Theme';

import PageMainMenu from './PageMainMenu'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const ImageComponent = muiStyled('img')({});



export default function PageNavBarMobile() {
    const navigate = useNavigate()

    const [sessionUpdated, setSessionUpdated] = React.useState(false)

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const onMenuItem = React.useCallback((key: string) => {
        setOpenDrawer(false)
    }, [])



    return (
        <>
            <Box sx={{ position: 'absolute', right: 130, top: 27, display: 'flex', alignItems: 'center' }}>
                {/* <a href="tel:+37498765432"><ImageComponent sx={{ width: 'xx-large' }} src="images/call-icon.svg"></ImageComponent></a> */}
                <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                    <ImageComponent src="images/call-icon.svg"></ImageComponent>
                    {/* Open dialog */}
                </Button>
                {/* <a href={`mailto:law@gmail.com`}><ImageComponent sx={{ width: 'xx-large', ml: 4 }} src="images/mail-icon.svg"></ImageComponent></a> */}
                {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>info@---.com</Typography> */}
            </Box>
            <Button onClick={() => setOpenDrawer(!openDrawer)} sx={{ position: 'absolute', right: 40, top: 25, color: 'white' }}>
                <MenuIcon
                    sx={{
                        //color: theme_.navButtonTextColor,
                        width: 60,
                        height: 60,
                    }}
                />
            </Button>


            <PageMainMenu
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onSelected={onMenuItem}
            />



            <Dialog open={open} onClose={handleClose} PaperProps={{
                sx: {
                    minWidth: '80%', maxWidth: "96%", background: `linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%)`,
                }
            }}>
                <DialogTitle sx={{ m: 0, ml: 1, p: 2, fontSize: 'xx-large', color: '#2f0707', fontWeight: 'bold' }} id="customized-dialog-title">
                    ԿԱՊ
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: theme_.themeColor, fontSize: 'xxx-large' }} />
                </IconButton>
                <Divider sx={{ width: '100%', backgroundColor: theme_.themeColor }} />

                <DialogContent sx={{ display: 'grid', justifyContent: 'center', p: 5 }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                        <a href="tel:+37443408800"><ImageComponent sx={{ width: 65 }} src="images/call-icon.svg"></ImageComponent></a>
                        <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large', marginLeft: 30, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                        {/* <ImageComponent sx={{ width: 88 }} src="images/whatsapp.png"></ImageComponent> */}
                        <a href="https://api.whatsapp.com/send?phone=+37443408800"> <ImageComponent sx={{ width: 88 }} src="images/whatsapp.png"></ImageComponent></a>
                        <a href="https://api.whatsapp.com/send?phone=+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large', marginLeft: 15, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                    </Box>


                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a href="viber://chat?number=37443408800"> <ImageComponent sx={{ width: 82 }} src="images/viber.png"></ImageComponent></a>
                    <a href="viber://chat?number=37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large',marginLeft: 20, color: "#2f0707", textDecoration: 'none', marginTop: -5,}}>+(374) 43 408 800</a>
                </Box>

                </DialogContent>
            </Dialog>
        </>
    )
}
