import React from "react";
import Env from 'env'

import { Box, Button, Divider, Typography, styled } from "@mui/material";

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";
import { Link } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ImageComponent = muiStyled('img')({});
const IframeElement = styled('iframe')({});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


export default function ContactUsPage() {

    const [t] = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 1, display: 'grid' }}>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                    <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 40 }}>{t('ԿԱՊ')}  </Typography>
                    <ImageComponent sx={{ position: 'absolute', width: '100%', height: 150, zIndex: -1 }} src="images/law/contact.jpg"></ImageComponent>
                </Box>

                <Box sx={{ display: 'grid' }}>

                    <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', width: '96%' }}>

                        {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, textAlign: 'center', marginTop: 10 }}>{t('Provided below are the contact details for LIMITED LIABILITY COMPANY')}</Typography> */}
                        <Typography sx={{ fontFamily: theme_.fontType, fontSize: 26, color: '#2f0707', fontWeight: 'bold', px: 3, textAlign: 'center', marginTop: 10 }}>{t(`Միայնակ մի՛ դիմակայեք իրավական մարտահրավերներին: Մենք այստեղ ենք՝ լսելու, խորհուրդ տալու և ձեր իրավունքները պաշտպանելու համար:`)} </Typography>

                    </Box>
                    <Box sx={{ width: '98%', justifySelf: 'center', mt: 5 }}>
                        <IframeElement

                            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1650306881297!2d44.4979069!3d40.2053918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2s15%20Aram%20Khachatrian%20St%2C%20Yerevan%200012!5e0!3m2!1sru!2s!4v1669129376957!5m2!1sru!2s"
                            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d440.9233921673118!2d44.509568985309464!3d40.184687680196205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd1d59f152b5%3A0xf941aed43f708c3e!2s56%20Pushkin%20St%2C%20Yerevan%200002!5e0!3m2!1sru!2sam!4v1710797589219!5m2!1sru!2sam'
                            loading='lazy'
                            sx={{
                                filter: 'invert(90%)',
                                width: '100%',
                                height: 400,
                                borderRadius: 4,
                            }}
                            frameBorder='0'
                        />

                    </Box>
                    <Box sx={{ justifySelf: 'center' }}>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 5 }}>   */}
                        {/* <Box sx={{ width: 400, paddingBottom: 10, marginLeft: 2 }}> */}

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                            <a href={`mailto:info@kalashyanlawfirm.com`}><ImageComponent src="images/mail-icon.svg"></ImageComponent></a>
                            <a href={`mailto:info@kalashyanlawfirm.com`} style={{ fontFamily: theme_.fontType, fontSize: 26, marginLeft: 15, color: "#2f0707", textDecoration: 'none' }}>info@kalashyanlawfirm.com</a>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 4, ml: -1 }}>
                            <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                                <ImageComponent src="images/call-icon.svg"></ImageComponent>
                                {/* Open dialog */}
                            </Button>

                            {/* <a href="tel:+37443408800"><ImageComponent src="images/call-icon.svg"></ImageComponent></a> */}
                            <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                                <Typography sx={{ fontFamily: theme_.fontType, fontSize: 26, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</Typography>
                                {/* Open dialog */}
                            </Button>

                            {/* <a href="tel:+37443408800"><ImageComponent src="images/call-icon.svg"></ImageComponent></a>
                                <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 26, marginLeft: 14, color: "#2f0707", textDecoration: 'none' }}>+37494438708</a> */}

                        </Box>

                        {/* </Box> */}

                        {/* </Box> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 4, width: '100%' }}>
                            <a href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} ><ImageComponent src="images/location-icon.svg"></ImageComponent></a>
                            <a href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} style={{ fontFamily: theme_.fontType, fontSize: 26, marginLeft: 15, color: "#2f0707", textDecoration: 'none', fontWeight: 'bold' }}>ՀՀ, ք․ Երևան, Պուշկինի փող․, 56/1</a>
                        </Box>
                    </Box>
                </Box>

            </Box>


            <Dialog open={open} onClose={handleClose} PaperProps={{
                sx: {
                    minWidth: '80%', maxWidth: "96%", background: `linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%)`,
                }
            }}>
                <DialogTitle sx={{ m: 0, ml: 1, p: 2, fontSize: 'xx-large', color: '#2f0707', fontWeight: 'bold' }} id="customized-dialog-title">
                    ԿԱՊ
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: theme_.themeColor, fontSize: 'xxx-large' }} />
                </IconButton>
                <Divider sx={{ width: '100%', backgroundColor: theme_.themeColor }} />

                <DialogContent sx={{ display: 'grid', justifyContent: 'center', p: 5 }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, }}>
                        <a href="tel:+37443408800"><ImageComponent sx={{ width: 65 }} src="images/call-icon.svg"></ImageComponent></a>
                        <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large', marginLeft: 30, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                        {/* <ImageComponent sx={{ width: 88 }} src="images/whatsapp.png"></ImageComponent> */}
                        <a href="https://api.whatsapp.com/send?phone=+37443408800"> <ImageComponent sx={{ width: 88 }} src="images/whatsapp.png"></ImageComponent></a>
                        <a href="https://api.whatsapp.com/send?phone=+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large', marginLeft: 15, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                        <a href="viber://chat?number=37443408800"> <ImageComponent sx={{ width: 82 }} src="images/viber.png"></ImageComponent></a>
                        <a href="viber://chat?number=37443408800" style={{ fontFamily: theme_.fontType, fontSize: 'xx-large', marginLeft: 20, color: "#2f0707", textDecoration: 'none', marginTop: -5, }}>+(374) 43 408 800</a>
                    </Box>


                </DialogContent>
            </Dialog>
        </>
    }

    return <>

        <Box sx={{ width: '100%', marginTop: 5, display: 'grid' }}>

            <Box sx={{ display: 'flex', width: '100%', height: 250, justifyContent: 'center', alignItems: 'center', marginTop: 18, '@media screen and (max-width: 1400px)': { mt: 5 } }}>
                {/* <Typography sx={{ fontFamily: theme_.fontType, color: 'white', fontSize: 35, marginLeft: 3 }}>{t('ԿԱՊ')}  </Typography> */}
                <Typography sx={{ fontFamily: theme_.fontType, color: 'white', width: '80%', fontSize: 34, marginLeft: 3,'@media screen and (max-width: 1400px)': { fontSize: 30 } }}>{t(`Միայնակ մի՛ դիմակայեք իրավական մարտահրավերներին: Մենք այստեղ ենք՝ լսելու, խորհուրդ տալու և ձեր իրավունքները պաշտպանելու համար:`)}  </Typography>
                <ImageComponent sx={{ position: 'absolute', left: 0, right: 0, width: '100%', height: '250px', zIndex: -1, opacity: '80%' }} src="images/law/contact.jpg"></ImageComponent>
            </Box>

            <Box sx={{
                // display: 'grid',
                // alignItems: 'center',
                // justifyContent: 'center'
            }}>

                {/* <Box sx={{ display: 'grid', alignItems: 'center', justifyContent: 'center', width: '100%' }}> */}

                {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, textAlign: 'center', marginTop: 15 }}>{t('We are delighted to assist you and address any questions or inquiries regarding our services or business.')}</Typography> */}

                {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, textAlign: 'center', marginTop: 15 }}>{t(`Don't Face Legal Challenges Alone. Our Attorneys are Here to Listen, Advise, and Champion Your Rights. `)}</Typography> */}

                {/* </Box> */}
                <Box sx={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                    <Box sx={{ width: '40%', ml: 27, '@media screen and (max-width: 1400px)': { ml: 20 } }}>
                        <IframeElement
                            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1650306881297!2d44.4979069!3d40.2053918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2s15%20Aram%20Khachatrian%20St%2C%20Yerevan%200012!5e0!3m2!1sru!2s!4v1669129376957!5m2!1sru!2s"
                            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d440.9233921673118!2d44.509568985309464!3d40.184687680196205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd1d59f152b5%3A0xf941aed43f708c3e!2s56%20Pushkin%20St%2C%20Yerevan%200002!5e0!3m2!1sru!2sam!4v1710797589219!5m2!1sru!2sam'
                            loading='lazy'
                            sx={{
                                filter: 'invert(90%)',
                                width: '100%',
                                height: 400,
                                '@media screen and (max-width: 1400px)': { height: 300 },
                                borderRadius: 4,
                            }}
                            frameBorder='0'
                        />
                    </Box>
                    <Box sx={{ width: '60%', display: 'grid', gridGap: 40, alignItems: 'center', justifyContent: 'space-around' }}>  {/* I added paddingBottom just for DEMO */}
                        {/* <Box sx={{ width: 600, paddingBottom: 10, marginLeft: 30 }}> */}

                        <Box sx={{
                            display: 'flex', alignItems: 'center', width: 480,
                        }}>
                            <a href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} target="_blank" rel="noreferrer"><ImageComponent src="images/location-icon.svg" sx={{
                                '@media screen and (max-width: 1400px)': { width: 45 },
                            }} /></a>
                            {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>{t('ADDRESS')} </Typography> */}
                            <a href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}><Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2.5, color: "#2f0707", fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 19 } }}>ՀՀ, ք․ Երևան, Պուշկինի փող․, 56/1</Typography></a>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, ml: -1 }}>
                            <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                                <ImageComponent src="images/call-icon.svg" sx={{
                                    '@media screen and (max-width: 1400px)': { width: 45 },

                                }}></ImageComponent>
                                {/* Open dialog */}
                            </Button>

                            {/* <a href="tel:+37443408800"><ImageComponent src="images/call-icon.svg"></ImageComponent></a> */}
                            <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                                <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, color: "#2f0707", textDecoration: 'none', '@media screen and (max-width: 1400px)': { fontSize: 19 }, }}>+(374) 43 408 800</Typography>
                                {/* Open dialog */}
                            </Button>
                            {/* <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 14, color: "#2f0707", textDecoration: 'none' }}>+37494438708</a> */}
                            {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>+374------</Typography> */}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <a href={`mailto:info@kalashyanlawfirm.com`}><ImageComponent src="images/mail-icon.svg" sx={{
                                '@media screen and (max-width: 1400px)': { width: 45 },

                            }} /></a>
                            {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>info@---.com</Typography> */}
                            <a href={`mailto:info@kalashyanlawfirm.com`} style={{ textDecoration: 'none' }}><Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2.5, color: "#2f0707", '@media screen and (max-width: 1400px)': { fontSize: 19 }, }}>info@kalashyanlawfirm.com</Typography></a>

                        </Box>
                        {/* </Box> */}

                    </Box>
                </Box>


            </Box>

        </Box>

        <Dialog open={open} onClose={handleClose} PaperProps={{
            sx: {
                minWidth: 500, maxWidth: 900, background: `linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%)`,

            }
        }}>
            <DialogTitle sx={{ m: 0, p: 2, color: '#2f0707', fontWeight: 'bold' }} id="customized-dialog-title">
                ԿԱՊ
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ color: theme_.themeColor }} />
            </IconButton>
            <Divider sx={{ width: '100%', backgroundColor: theme_.themeColor }} />

            <DialogContent sx={{ display: 'grid', justifyContent: 'center', p: 5 }} >
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <a href="tel:+37443408800"><ImageComponent sx={{ width: 45 }} src="images/call-icon.svg"></ImageComponent></a>
                    <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 23, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a href="https://api.whatsapp.com/send?phone=+37443408800"> <ImageComponent sx={{ width: 60 }} src="images/whatsapp.png"></ImageComponent></a>
                    <a href="https://api.whatsapp.com/send?phone=+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 15, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                </Box>


                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a href="viber://chat?number=37443408800"> <ImageComponent sx={{ width: 54 }} src="images/viber.png"></ImageComponent></a>
                    <a href="viber://chat?number=37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 20, color: "#2f0707", textDecoration: 'none', marginTop: -5, }}>+(374) 43 408 800</a>
                </Box>

            </DialogContent>
        </Dialog>


    </>

}


