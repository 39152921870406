import React from "react";
import Helmet from "react-helmet";

export interface HeaderProps {
    title?: string;
};

export default function HtmlHeader(props: HeaderProps | undefined) {
    let title = "Kalashyan Law Firm";
    if (props) {
        if (props.title)
            title = props.title;
    }

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
}
