import styled from 'styled-components/macro';

import theme_ from 'theme/Theme';
import Env from 'env';
import { styled as muiStyled } from '@mui/system';

const ImageComponent = muiStyled('img')({});


const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: ${theme_.pageHeaderHeight}px;
    height: ${theme_.pageHeaderHeight}px;
`;

// let LogoImage

// if (Env.isMobile) {
//     LogoImage = styled.img.attrs({
//         //src: "/images/microscope.svg",
//         // src: "/images/company-logo.svg",
//         // src: "/images/law/logo.jpg",
//         src: "/images/law/logo.svg",
//         alt: "KALASHYAN LAW FIRM",
//     })`
//         padding: ${theme_.pageHorzMargin}px;
//         // width: ${theme_.pageHeaderHeight}px;
//         // height: ${theme_.pageHeaderHeight}px;
//         width: 300px;
//         height: auto;
//         // margin-left: 25px;
//         margin-left: 56px;
//         margin-top: 10px;
//     `;
// } else {
//     LogoImage = styled.img.attrs({
//         //src: "/images/microscope.svg",
//         src: "/images/law/logo.svg",
//         alt: "KALASHYAN LAW FIRM",
//     })`
//         padding: ${theme_.pageHorzMargin}px;
//         // width: ${theme_.pageHeaderHeight}px;
//         // height: ${theme_.pageHeaderHeight}px;
//         width: 350px;
//         height: auto;
//         // margin-left: 25px;
//         margin-left: 107px;
//         margin-top: 15px;
//         @media screen and (max-width: 1400px)': { width: 250px }
//         `;
//     // padding: 10px 20px;
//     // width: 90px;
// }


export default function PageLogo() {
    if (Env.isMobile) {
        return <Container>
            <ImageComponent src="/images/law/logo.svg"
                alt="KALASHYAN LAW FIRM"
                sx={{
                    padding: `${theme_.pageHorzMargin}px`,
                    width: '300px',
                    height: 'auto',
                    marginLeft: '56px',
                    marginTop: '10px',
                }}
            />
            {/* // <LogoImage /> */}
        </Container>;
    }

    return <Container>
        <ImageComponent src="/images/law/logo.svg"
            alt="KALASHYAN LAW FIRM"
            sx={{
                padding: `${theme_.pageHorzMargin}px`,
                width: '350px',
                height: 'auto',
                marginLeft: '107px',
                marginTop: '15px',
                '@media screen and (max-width: 1400px)': {width: '250px' },
            }}
        />
        {/* // <LogoImage /> */}
    </Container>;
}
