import React from "react";
import Env from 'env'

import { styled as muiStyled } from '@mui/system';
import { Box, Divider, Grid, Typography } from "@mui/material";

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import session_ from "../language/languageProcess";


const ImageComponent = muiStyled('img')({});


const styles = {
    paperContainer: {
        // height: 800,
        backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        // width: `calc(100vw + 48px)`,
        // margin: -24,
        // padding: 24,
    }
};


export default function ProductsPage() {

    const [t] = useTranslation();


    if (Env.isMobile) {
        return <>

            <Box sx={{ width: '100%', marginTop: 40 }}>

                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                    {/* <Divider sx={{ width: '40px', borderBottomWidth: 8, backgroundColor: 'black', marginLeft: -3 }}></Divider> */}
                    <Typography sx={{ textAlign: 'center', color: 'white', fontSize: 45 }}>{t('ԾԱՌԱՅԱՌՈՒԹՅՈՒՆՆԵՐ')}  </Typography>
                    <ImageComponent sx={{ position: 'absolute', width: '100%', height: 'auto', zIndex: -1 }} src="images/law/service.png"></ImageComponent>
                </Box>


                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ justifyContent: 'center' }}>
                    <Grid item xs={6}>
                        <Box sx={{
                            marginTop: 15,
                            marginLeft: 4,

                        }}>
                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                                backgroundImage: `url("images/stamp-and-ink-pad-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ՊԱՅՄԱՆԱԳՐԱՅԻՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒ -')} </Typography>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography>

                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 20, mt: 2 }}>
                                            {t(`Tsiran-It PCB has the tools and knowledge to design your PCB no matter how large or small. We use the industry’s top tier design tools and are driven to be the best. High speed, multi layer PCB designs – Bus routing, differential pairs, matched lengths.`)}
                                        </Typography> */}
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1, mt: 2 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Պայմանագրերի պատրաստում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Գործարքների իրավաբանական սպասարկում և ուղեկցում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Գործարքների ռիսկերի գնահատում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Վեճերի լուծում (մինչդատական և դատական կարգով)')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Խորհրդատվության տրամադրում')}
                                        </Typography>
                                    </Box>
                                </AccordionDetails>

                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            marginTop: 15,
                            ml: 2
                        }}>

                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/separated-couple-separate-split-divorce-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԸՆՏԱՆԵԿԱՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒ -')} </Typography>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography>
                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 20, mt: 2 }}>
                                            {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)}
                                        </Typography> */}
                                    </Box>
                                </AccordionSummary>


                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ամուսնալուծությունը՝ դատական կարգով')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ալիմենտի (ապրուստավճար) բռնագանձում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Երեխայի բնակության վայրի որոշումը')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դիմումի և/կամ Հայցադիմումի կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Պաշտպանություն դատարանում')}
                                        </Typography>
                                    </Box>


                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>


                                </AccordionDetails>

                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>

                        <Box sx={{

                            marginTop: 15,
                            marginLeft: 4,

                        }}>
                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/shaking-hands-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԱՇԽԱՏԱՆՔԱՅԻՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒ -')} </Typography>
                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography>
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Աշխատանքային վեճերի լուծում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Խորհրդատվության տրամադրում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Հայցադիմումի կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Աշխատավարձի և/կամ հարկադիր պարապուրդի բռնագանձում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Վերականգնում աշխատանքում')}
                                        </Typography>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>


                    <Grid item xs={6}>

                        <Box sx={{

                            marginTop: 15,
                            ml: 2
                        }}>
                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/building-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԺԱՌԱՆԳԱԿԱՆ ԳՈՐԾԵՐ')} </Typography>
                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography> */}
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ժառանգական գործերով խորհրդատվություն')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Կտակն անվավեր և/կամ մասնակի անվավեր ճանաչելը')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Իրավաբանական ուղեկցում նոտարական գրասենյակ')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Հայցադիմումի կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ներկայացուցչություն')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>


                    <Grid item xs={6}>

                        <Box sx={{

                            marginTop: 15,
                            marginLeft: 4,

                        }}>
                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ՀԵՂԻՆԱԿԱՅԻՆ ԻՐԱՎՈՒՆՔՆԵՐԻ ԳՈՐԾԵՐՈՎ')} </Typography>
                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography> */}
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ապրանքանիշերի գրանցում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Մտավոր սեփականությանը վերաբերող խորհրդատվություն')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Վեճերի լուծումը՝ դատական կարգով')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Հայցադիմումի կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>

                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>

                    <Grid item xs={6}>

                        <Box sx={{

                            marginTop: 15,
                            // marginLeft: -1
                            ml: 2
                        }}>
                            <Accordion sx={{
                                width: 350,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/wallet-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 350, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ՍՆԱՆԿՈՒԹՅԱՆ ՎԵՐԱԲԵՐՅԱԼ ԳՈՐԾԵՐՈՎ')} </Typography>
                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography> */}
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Խորհրդատվության տրամադրում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ֆիզիկական անձին սնանկ ճանաչելը')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Իրավաբանական անձին սնանկ ճանաչելը')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Հարկադիր սնանկ ճանաչելը')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Սնանկության վարույթի կազմում')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>
                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>

                    <Grid item xs={6}>

                        <Box sx={{

                            marginTop: 15,

                        }}>
                            <Accordion sx={{
                                width: 370,
                                minHeight: 650,
                                borderRadius: 5,
                                boxShadow: 3,
                                backgroundColor: 'white',
                                backgroundImage: `url("images/scale-svgrepo-com.svg")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right',
                                backgroundSize: "120px"
                            }}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        width: 370, height: 650
                                    }}
                                >
                                    <Box sx={{ textAlign: 'center', width: 300 }}>

                                        <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ՆԵՐԿԱՅԱՑՈՒՑՉՈՒԹՅՈՒՆ՝ ՎԱՐՉԱԿԱՆ ՎԱՐՈՒՅԹՈՒՄ')} </Typography>
                                        {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 25, fontWeight: 'bold' }}> {t('ԹՅՈՒՆՆԵՐ')} </Typography> */}
                                    </Box>
                                </AccordionSummary>



                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Վարչական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Խորհրդատվության տրամադրում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դիմումների և վարչական բողոքների կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Հայցադիմումների կազմում')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ներկայացուցչություն')}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Ներկայացուցչություն դատարանում')}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                        <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                        <Typography sx={{ ml: 0.5, mt: -1, fontSize: 'x-large' }}>
                                            {t('Դատական ակտերի բողոքարկում')}
                                        </Typography>
                                    </Box>
                                </AccordionDetails>

                            </Accordion>
                        </Box>

                    </Grid>



                </Grid>

            </Box>
        </>
    }


    return <>

        <Box sx={{ width: '100%', marginTop: 60, '@media screen and (max-width: 1400px)': { marginTop: 40 } }}>


            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 0 }}>
                {/* <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: '#fd8f20', marginLeft: -3, marginTop: 30 }}></Divider> */}
                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', color: 'white', fontSize: 35, marginTop: 20 }}>{t('ԾԱՌԱՅԱՌՈՒԹՅՈՒՆՆԵՐ')}  </Typography>
                <ImageComponent sx={{ position: 'absolute', width: '100%', height: 400, zIndex: -1, marginTop: 20 }} src="images/law/service.png"></ImageComponent>
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 0 }}>

                {/* <Box sx={{ */}
                <Box sx={{

                    marginTop: 20,


                }}>
                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // marginTop: 20,
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        backgroundImage: `url("images/stamp-and-ink-pad-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18 }}> {t('IT Network Solution')} </Typography> */}
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ՊԱՅՄԱՆԱԳՐԱՅԻՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒԹՅՈՒՆՆԵՐ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB has the tools and knowledge to design your PCB no matter how large or small. We use the industry’s top tier design tools and are driven to be the best. High speed, multi layer PCB designs – Bus routing, differential pairs, matched lengths.`)} */}
                                    {/* {t(` book. `)} */}
                                </Typography>
                            </Box>
                            {/* <ImageComponent src="images/legal-hammer-symbol-svgrepo-com.svg"></ImageComponent> */}
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Պայմանագրերի պատրաստում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Գործարքների իրավաբանական սպասարկում և ուղեկցում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Գործարքների ռիսկերի գնահատում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Վեճերի լուծում (մինչդատական և դատական կարգով)')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Խորհրդատվության տրամադրում')}
                                </Typography>
                            </Box>


                            {/* {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Software development')}
                                    </Typography>
                                </Box>
                            }

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Prototype building')}
                                    </Typography>
                                </Box>
                            }

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Bench testing and debug')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Full turnkey production of finished product')}
                                </Typography>
                            </Box> */}
                            {/* </Typography> */}
                        </AccordionDetails>


                        {/* </Box> */}
                    </Accordion>

                </Box>



                <Box sx={{

                    marginTop: 20,
                }}>

                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        backgroundImage: `url("images/separated-couple-separate-split-divorce-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px",


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> {t('RF Engineering & PCB Design')} </Typography> */}
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ԸՆՏԱՆԵԿԱՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒԹՅՈՒՆՆԵՐ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)} */}
                                    {/* {t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `)} */}
                                </Typography>

                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 8, fontSize: 18 }}> {t('Embedded Systems Design & Development')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ամուսնալուծությունը՝ դատական կարգով')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Ալիմենտի (ապրուստավճար) բռնագանձում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Երեխայի բնակության վայրի որոշումը')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դիմումի և/կամ Հայցադիմումի կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Պաշտպանություն դատարանում')}
                                </Typography>
                            </Box>

                            {/* {session_.lang !== 'am' && <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Antenna tuning and impedance matching')}
                                </Typography>
                            </Box>
                            }
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Low noise amplifiers')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Base amplifiers')}
                                </Typography>
                            </Box>

                            {session_.lang !== 'am' &&
                                <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                    <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                    <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                        {t('Prototype building')}
                                    </Typography>
                                </Box>
                            }

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Bench testing and debug')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Full turnkey production of finished product')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Vector modulators')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Phase shifter')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('High frequency switches')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Microstrip filters')}
                                </Typography>
                            </Box> */}

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                        </AccordionDetails>



                    </Accordion>
                </Box>
                {/* <ImageComponent sx={{}} src="images/embedded-image.svg"></ImageComponent> */}

                <Box sx={{
                    // width: 250,
                    // height: 250,
                    // borderRadius: 5,
                    // boxShadow: 3,
                    // backgroundColor: 'white',
                    marginTop: 20,
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    // backgroundImage: `url("images/measurement-image.svg")`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'right',
                    // backgroundSize: "102px"

                }}>
                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // backgroundImage: `url("images/handshake-deal-svgrepo-com.svg")`,
                        backgroundImage: `url("images/shaking-hands-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, ml: 2, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ԱՇԽԱՏԱՆՔԱՅԻՆ ԻՐԱՎԱՀԱՐԱԲԵՐՈՒԹՅՈՒՆՆԵՐ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)} */}
                                    {/* {t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `)} */}
                                </Typography>


                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}>{t('Measurement and Control of RF systems')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Աշխատանքային վեճերի լուծում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Խորհրդատվության տրամադրում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Հայցադիմումի կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Աշխատավարձի և/կամ հարկադիր պարապուրդի բռնագանձում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Վերականգնում աշխատանքում')}
                                </Typography>
                            </Box>

                        </AccordionDetails>


                    </Accordion>
                </Box>






                <Box sx={{
                    marginTop: 20,
                }}>
                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        backgroundImage: `url("images/building-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250,
                                '@media screen and (max-width: 1400px)': { width: 280 },
                            }}
                        >
                            <Box sx={{ textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 }, }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, ml: 2, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ԺԱՌԱՆԳԱԿԱՆ ԳՈՐԾԵՐ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)} */}
                                    {/* {t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `)} */}
                                </Typography>


                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}>{t('Measurement and Control of RF systems')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ժառանգական գործերով խորհրդատվություն')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Կտակն անվավեր և/կամ մասնակի անվավեր ճանաչելը')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Իրավաբանական ուղեկցում նոտարական գրասենյակ')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Հայցադիմումի կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ներկայացուցչություն')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                        </AccordionDetails>


                    </Accordion>
                </Box>



                {/* <Box sx={{
                    width: 250,
                    height: 250,
                    borderRadius: 5,
                    boxShadow: 3,
                    backgroundColor: 'white',
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url("images/telecommunication-image.svg")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right',
                    backgroundSize: "85px"

                }}>

                    <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}> {t('Telecommunication Equipment Design & Development')} </Typography>

                </Box> */}

            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

                {/* <Box sx={{ */}
                <Box sx={{

                    marginTop: 15,


                }}>
                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // marginTop: 20,
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        // backgroundImage: `url("images/copyright-symbol-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18 }}> {t('IT Network Solution')} </Typography> */}
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ՀԵՂԻՆԱԿԱՅԻՆ ԻՐԱՎՈՒՆՔՆԵՐԻ ԳՈՐԾԵՐՈՎ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB has the tools and knowledge to design your PCB no matter how large or small. We use the industry’s top tier design tools and are driven to be the best. High speed, multi layer PCB designs – Bus routing, differential pairs, matched lengths.`)} */}
                                    {/* {t(` book. `)} */}
                                </Typography>
                            </Box>
                            {/* <ImageComponent src="images/legal-hammer-symbol-svgrepo-com.svg"></ImageComponent> */}
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ապրանքանիշերի գրանցում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Մտավոր սեփականությանը վերաբերող խորհրդատվություն')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Վեճերի լուծումը՝ դատական կարգով')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Հայցադիմումի կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>
                        </AccordionDetails>


                        {/* </Box> */}
                    </Accordion>

                </Box>



                <Box sx={{

                    marginTop: 15,
                }}>

                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        backgroundImage: `url("images/wallet-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px",


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}> {t('RF Engineering & PCB Design')} </Typography> */}
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ՍՆԱՆԿՈՒԹՅԱՆ ՎԵՐԱԲԵՐՅԱԼ ԳՈՐԾԵՐՈՎ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)} */}
                                    {/* {t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `)} */}
                                </Typography>

                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 8, fontSize: 18 }}> {t('Embedded Systems Design & Development')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Խորհրդատվության տրամադրում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Ֆիզիկական անձին սնանկ ճանաչելը')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3, fontSize: session_.lang === 'am' ? 14 : null }}>
                                    {t('Իրավաբանական անձին սնանկ ճանաչելը')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Հարկադիր սնանկ ճանաչելը')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Սնանկության վարույթի կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                        </AccordionDetails>



                    </Accordion>
                </Box>
                {/* <ImageComponent sx={{}} src="images/embedded-image.svg"></ImageComponent> */}

                <Box sx={{
                    marginTop: 15,
                }}>
                    <Accordion sx={{
                        width: 350,
                        '@media screen and (max-width: 1400px)': { width: 280 },
                        // width: 250,
                        // height: 250,
                        minHeight: 250,
                        borderRadius: 5,
                        boxShadow: 3,
                        backgroundColor: 'white',
                        // backgroundImage: `url("images/legal-hammer-symbol-svgrepo-com.svg")`,
                        backgroundImage: `url("images/scale-svgrepo-com.svg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right',
                        backgroundSize: "102px"


                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: theme_.themeColor, fontSize: 35 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                width: 350, height: 250, '@media screen and (max-width: 1400px)': { width: 280 },

                            }}
                        >
                            <Box sx={{
                                textAlign: 'center', width: 300, '@media screen and (max-width: 1400px)': { width: 200 },
                            }}>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 18, ml: 2, fontWeight: 'bold','@media screen and (max-width: 1400px)': { fontSize: 12 } }}> {t('ՆԵՐԿԱՅԱՑՈՒՑՉՈՒԹՅՈՒՆ՝ ՎԱՐՉԱԿԱՆ ՎԱՐՈՒՅԹՈՒՄ')} </Typography>
                                <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', fontSize: 12, mt: 2 }}>
                                    {/* {t(`Tsiran-It PCB Design, Inc. can provide a complete turnkey RF engineering and manufacturing solution. We offer RF design, testing, simulation and manufacturing services.`)} */}
                                    {/* {t(`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `)} */}
                                </Typography>


                                {/* <Typography sx={{ fontFamily: theme_.fontType, textAlign: 'center', padding: 7, fontSize: 18 }}>{t('Measurement and Control of RF systems')} </Typography> */}

                            </Box>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Վարչական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Խորհրդատվության տրամադրում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դիմումների և վարչական բողոքների կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Հայցադիմումների կազմում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ներկայացուցչություն')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Ներկայացուցչություն դատարանում')}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'start', mb: 1 }}>
                                <CircleIcon sx={{ fontSize: 'small', color: theme_.themeColor }} />
                                <Typography sx={{ ml: 0.5, mt: -0.3 }}>
                                    {t('Դատական ակտերի բողոքարկում')}
                                </Typography>
                            </Box>

                        </AccordionDetails>


                    </Accordion>
                </Box>

            </Box>

        </Box >
    </>

}

