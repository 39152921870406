import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import Env from 'env'

import { styled as muiStyled } from '@mui/system';

import { createTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import theme_ from "theme/Theme";
import { red } from "@mui/material/colors";


const ImageComponent = muiStyled('img')({});


const theme = createTheme({
    typography: {
        fontFamily: 'Inter',

    }
});

export default function AboutUsPage() {

    const [t] = useTranslation();


        if (Env.isMobile ) {
        return <>

            <Box sx={{ width: '100%', marginTop: 150, display: 'flex' }}>

                <Box sx={{ width: 'auto', marginTop: 20 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: theme_.themeColor, marginLeft: 5, }}></Divider>
                        <Typography sx={{ fontFamily: theme_.fontType, color: theme_.themeColor, fontWeight: 'bold', fontSize: 35, marginLeft: 3 }}>{t('ՄԵՐ ՄԱՍԻՆ')}</Typography>
                    </Box>
                    {/* <Box sx={{ mt: -30 }}>
                        <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 400, height: 'auto', marginLeft: 5 }} src="images/law/about.png"></ImageComponent>
                        <Box sx={{ border: 1, width: 420, height: 280, color: theme_.themeColor, borderWidth: 5, marginLeft: 7, marginTop: -30 }}></Box>
                    </Box> */}
                    <Box sx={{ display: 'flex', float: 'left', marginRight: 5 }}>

                        <Typography sx={{ color: 'black', fontSize: 27, marginLeft: 5, marginTop: 3 }}>
                            {/* {t('Our team of Ph.D. holders, engineers, statisticians, lawyers, and business management consultants has the ability to translate highly technical information into meaningful insights for better decision making. Our extensive domain expertise and meticulous approach help us provide deeper levels of insights than others in the industry. We’re more than just an IT research and developer company. Our engineers have all the skills to cover your hardware, software and networking needs. We are ready to provide optimal and thoughtful solutions according to customer requirements. Our goal is put together a full-scale electronic device. We will take you great product conceptualization to trial manufacturing from scratch to mass production.')} */}
                            {t(`
                    «Քալաշյան լո ֆիրմ» իրավաբանական գրասենյակը պատրաստակամ է դիմակայել և հաղթահարել իր վստահորդների իրավական մարտահրավերները։
          
                    `)}
                        </Typography>
                        {/* <Box sx={{ mt: -30 }}> */}
                        <Box sx={{ mt: 0 }}>
                            <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 400, height: 'auto', marginLeft: 0 }} src="images/law/about.png"></ImageComponent>
                            <Box sx={{ border: 1, width: 400, height: 280, color: theme_.themeColor, borderWidth: 5, marginLeft: 3, marginTop: -32}}></Box>
                        </Box>
                        {/* <Box sx={{mt: -30}}>
                            <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 250, height: 'auto', marginLeft: 5 }} src="images/law/about.png"></ImageComponent>
                            <Box sx={{ border: 1, width: 250, height: 150, color: theme_.themeColor, borderWidth: 4, marginLeft: 7, marginTop: -17 }}></Box>
                        </Box> */}
                    </Box>
                    <Typography sx={{ color: 'black', fontSize: 27, marginLeft: 5, marginTop: 3, width: 700 }}>
                        {t(`
                         Մեր թիմն, 
                         իր առանձնակի ջանասիրությամբ, անխոնջ աշխատում է, որպեսզի վստահորդը ստանա հնարավոր լավագույն արդյունքը։ 
                        Մենք ծառայություններ ենք մատուցում իրավաբանական և ֆիզիկական անձանց և հավասարապես հոգում ենք իրավական խնդիրների ամենախելամիտ լուծումները։
                        Մեր կողմից մատուցվող ծառայությունների աշխարհագրությունը լայն է․ այն ընդգկում է պայմանագրային, ընտանեկան, աշխատանքային, ժառանգական,
                        մտավոր սեփականության, սնանկության ոլորտները։ Առանձին հիշատակման կարիք ունեն մեր հատուկ առաջարկները, որոնք առայժմ նախատեսված են իրավաբանական անձանց համար։ 
                        Մենք ունենք երկարատև և կայուն փորձ իրավունքի տարբեր ոլորտներում մեր վստահորդների շահերը լավագույնս ներկայացնելու գործում և համոզված ենք, 
                        որ մենք կարող ենք միասին լուծել իրավական ամենաբարդ խնդիրները։ Մենք պատրաստակամ ենք օգնել մեր վստահորդներին դիմակայել և հաղթահարել իրավական բազմաբնույթ
                        խնդիրներն ու մարտահրավերները։ Մենք մեծ պատասխանատվությամբ և ուշադրությամբ ենք աշխատում մեր վստահորդների հետ՝ օրենքով չարգելված ցանկացած ճանապարհով
                        պաշտպանությունը ճիշտ կառուցելու համար, որպեսզի խնդրի լուծումը ստանա հնարավոր լավագույն արդյունքը։
                    `)}
                    </Typography>
                </Box>
                {/* <Box sx={{ marginRight: 5 }}>
                    <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 250, height: 'auto', marginLeft: 5 }} src="images/about-us-image.png"></ImageComponent>
                    <ImageComponent sx={{ marginTop: theme_.aboutUsImageMarginTop, width: 250, height: 'auto', marginLeft: 5 }} src="images/law/about.png"></ImageComponent>
                    <Box sx={{ border: 1, width: 250, height: 150, color: theme_.themeColor, borderWidth: 4, marginLeft: 7, marginTop: -17 }}></Box>
                </Box> */}

            </Box>
        </>
    }

    return <>

        <Box sx={{ width: '100%', marginTop: 70, display: 'flex', '@media screen and (max-width: 1400px)': { marginTop: 50 } }}>

            <Box sx={{ width: 1000, marginTop: 15 , '@media screen and (max-width: 1400px)': { marginTop: 5 }}}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Divider sx={{ width: '40px', borderBottomWidth: 5, backgroundColor: theme_.themeColor, marginLeft: 15 }}></Divider>
                    <Typography sx={{ fontFamily: 'Inter', color: theme_.themeColor, fontSize: 35, fontWeight: 'bold', marginLeft: 3 }}>{t('ՄԵՐ ՄԱՍԻՆ')}</Typography>
                </Box>

                <Typography sx={{ color: 'black', fontSize: 22, marginLeft: 15, marginTop: 3, '@media screen and (max-width: 1750px)': { fontSize: 18, width: 700 }, '@media screen and (max-width: 1500px)': { fontSize: 18, width: 700 }, '@media screen and (max-width: 1400px)': { fontSize: 'medium', width: 600 } }}>
                    {t(`
                    «Քալաշյան լո ֆիրմ» իրավաբանական գրասենյակը պատրաստակամ է դիմակայել և հաղթահարել իր վստահորդների իրավական մարտահրավերները։ Մեր թիմն, 
                    իր առանձնակի ջանասիրությամբ, անխոնջ աշխատում է, որպեսզի վստահորդը ստանա հնարավոր լավագույն արդյունքը։ 
                    Մենք ծառայություններ ենք մատուցում իրավաբանական և ֆիզիկական անձանց և հավասարապես հոգում ենք իրավական խնդիրների ամենախելամիտ լուծումները։
                    Մեր կողմից մատուցվող ծառայությունների աշխարհագրությունը լայն է․ այն ընդգկում է պայմանագրային, ընտանեկան, աշխատանքային, ժառանգական,
                    մտավոր սեփականության, 
                    սնանկության ոլորտները։ Առանձին հիշատակման կարիք ունեն մեր հատուկ առաջարկները, որոնք առայժմ նախատեսված են իրավաբանական անձանց համար։ 
                    Մենք ունենք երկարատև և կայուն փորձ իրավունքի տարբեր ոլորտներում մեր վստահորդների շահերը լավագույնս ներկայացնելու գործում և համոզված ենք, 
                    որ մենք կարող ենք միասին լուծել իրավական ամենաբարդ խնդիրները։
                    Մենք պատրաստակամ ենք օգնել մեր վստահորդներին դիմակայել և հաղթահարել իրավական բազմաբնույթ խնդիրներն ու մարտահրավերները։ Մենք մեծ պատասխանատվությամբ և ուշադրությամբ ենք աշխատում մեր վստահորդների հետ՝ օրենքով չարգելված ցանկացած ճանապարհով
                    պաշտպանությունը ճիշտ կառուցելու համար, որպեսզի խնդրի լուծումը ստանա հնարավոր լավագույն արդյունքը։
                    `)}
                    {/* // {t( 'Our team of Ph.D. holders, engineers, statisticians, lawyers, and business management consultants has the ability to translate highly technical information into meaningful insights for better decision making. Our extensive domain expertise and meticulous approach help us provide deeper levels of insights than others in the industry. We’re more than just an IT research and developer company. Our engineers have all the skills to cover your hardware, software and networking needs. We are ready to provide optimal and thoughtful solutions according to customer requirements. Our goal is put together a full-scale electronic device. We will take you great product conceptualization to trial manufacturing from scratch to mass production.')} */}
                </Typography>
            </Box>

            <Box sx={{width: 450,'@media screen and (max-width: 1400px)': { width: 1000}}}>
                {/* <ImageComponent sx={{ marginTop: 25, width: 450, height: 'auto', marginLeft: 25 }} src="images/about-us-image.png"></ImageComponent> */}
                <ImageComponent sx={{ marginTop: 40, width: 450, height: 'auto', marginLeft: 25,'@media screen and (max-width: 1850px)': { width: 450, ml: 20 }, '@media screen and (max-width: 1750px)': { width: 380, ml: -4 }, '@media screen and (max-width: 1500px)': { width: 300, ml: 1 }, '@media screen and (max-width: 1400px)': { width: 300, ml: 15, mt: 30 } }} src="images/law/about.png"></ImageComponent>
                {/* <ImageComponent sx={{ marginTop: 40, width: 450, height: 'auto', marginLeft: 25,'@media screen and (max-width: 1750px)': { width: 380, ml: -4 }, '@media screen and (max-width: 1500px)': { width: 300, ml: 1 }, '@media screen and (max-width: 1400px)': { width: 300, ml: 15, mt: 30 } }} src="images/law/about.png"></ImageComponent> */}
                <Box sx={{ border: 1, width: 450, height: 330, color: theme_.themeColor, borderWidth: 4, marginLeft: 30, marginTop: -37,'@media screen and (max-width: 1850px)': { width: 450, ml: 25 },'@media screen and (max-width: 1750px)': { width: 380, height: 290 , mt: -33, ml: 0}, '@media screen and (max-width: 1500px)': { width: 300, height: 210, mt: -22, ml: 6 },'@media screen and (max-width: 1400px)': { width: 300, height: 210, mt: -22, ml: 19 } }}></Box>

            </Box>

        </Box>
    </>

}

