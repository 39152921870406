
import styled from 'styled-components';

// import * as Session from 'api/session';
import theme_ from 'theme/Theme';
import { styled as muiStyled } from '@mui/system';

import PageMainMenu from './PageMainMenu'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
// import Env from 'env';
import React from 'react';
import { AppBar, Box, Button, Divider, IconButton, Menu, MenuItem, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import Env from 'env';
import PageNavBarMobile from './PageNavBarMobile';
// import PageNavBarMobile from './PageNavBarMobile';

import { Link } from 'react-scroll'

import { useTranslation } from 'react-i18next';

const ImageComponent = muiStyled('img')({});


const NavContainer = styled.div`
    position: absolute;
    // bottom: 30px;
    top: 45px;
    height: 20px;
    display: inline-block;
    // right: 590px;
    right: 110px;
`;

const NavContainerMobile = styled.div`
    position: absolute;

    top: 45px;
    height: 20px;
    display: inline-block;
    padding-left: 114px;
    right: 0px;
`;




interface PageNavProps {
    current: string;
}

export default function PageNav(props: PageNavProps) {
    const [t] = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    if (Env.isMobile) {
        return <>
            <PageNavBarMobile />

        </>;
    }

    return <>

        <NavContainer>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 53.5, mb: 3, mt: -3 }}>
                <a href="tel:+37498765432"><ImageComponent sx={{ width: 45 }} src="images/call-icon.svg"></ImageComponent></a>
                <a href="tel:+37498765432" style={{ fontFamily: theme_.fontType, fontSize: 'large', marginLeft: 14, color: "white", textDecoration: 'none' }}>+37498765432</a>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: -3, mb: 2, ml: 10.8 }}>
                <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                    <ImageComponent src="images/call-icon.svg" sx={{ width: 45, ml: 11, '@media screen and (max-width: 1400px)': { width: 35, ml: 22 } }}></ImageComponent>
                    {/* Open dialog */}
                </Button>

                {/* <a href="tel:+37443408800"><ImageComponent src="images/call-icon.svg"></ImageComponent></a> */}
                <Button onClick={handleClickOpen} sx={{ color: 'transparent', "&:hover": { backgroundColor: 'transparent' } }}>
                    <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, color: "white", textDecoration: 'none', '@media screen and (max-width: 1400px)': { fontSize: 16 } }}>+(374) 43 408 800</Typography>
                    {/* Open dialog */}
                </Button>

                {/* <a href="tel:+37498765432"><ImageComponent sx={{ width: 45, ml: 18 }} src="images/call-icon.svg"></ImageComponent></a>
                <a href="tel:+37498765432" style={{ fontFamily: theme_.fontType, fontSize: 'large', marginLeft: 14, color: "white", textDecoration: 'none' }}>+37498765432</a> */}
                <a href={`mailto:info@kalashyanlawfirm.com`}><ImageComponent sx={{ width: 45, ml: 4, '@media screen and (max-width: 1400px)': { width: 35 } }} src="images/mail-icon.svg"></ImageComponent></a>
                {/* <Typography sx={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 2 }}>info@---.com</Typography> */}
                {/* <a href={`mailto: law@gmail.com`} style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 15, color: "white", textDecoration: 'none' }}>info@kalashyanlawfirm.com</a> */}
                <a href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} target="_blank" rel="noreferrer"><ImageComponent src="images/location-icon.svg" sx={{ width: 45, ml: 4, '@media screen and (max-width: 1400px)': { width: 35 } }}></ImageComponent></a>

            </Box>


            {/* <Link style={{paddingRight: 8, marginLeft: 30 ,color: 'white', fontSize: 'large', cursor: 'pointer'}} activeClass="active" to="home" spy={true} smooth={true}>Home</Link> */}
            <Box sx={{ display: 'flex', '@media screen and (max-width: 1400px)': { mt: -1} }}>
                <Link to="about" spy={true} smooth={true}><Typography sx={{ fontFamily: theme_.fontType, paddingRight: 1, marginLeft: 3, color: 'white', fontSize: 'large', cursor: 'pointer', fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 'medium', ml: 9.8 } }}>{t('ՄԵՐ ՄԱՍԻՆ')}</Typography> </Link>
                <Link to="products" spy={true} smooth={true}><Typography sx={{ fontFamily: theme_.fontType, paddingRight: 1, marginLeft: 3, color: 'white', fontSize: 'large', cursor: 'pointer', fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 'medium' }, }}>{t('ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ')}</Typography></Link>
                <Link to="suggestions" spy={true} smooth={true}><Typography sx={{ fontFamily: theme_.fontType, paddingRight: 1, marginLeft: 3, color: 'white', fontSize: 'large', cursor: 'pointer', fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 'medium' } }}>{t('ԱՌԱՋԱՐԿՆԵՐ')}</Typography></Link>
                {/* <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="partners" spy={true} smooth={true}>{t('PARTNERS')}</Link> */}
                {/* <Link style={{ fontFamily: theme_.fontType, paddingRight: 8, marginLeft: 30, color: 'white', fontSize: 'large', cursor: 'pointer' }} to="careers" spy={true} smooth={true}>{t('CAREERS')}</Link> */}
                <Link to="contact" spy={true} smooth={true}><Typography sx={{ fontFamily: theme_.fontType, paddingRight: 1, marginLeft: 3, color: 'white', fontSize: 'large', cursor: 'pointer', fontWeight: 'bold', '@media screen and (max-width: 1400px)': { fontSize: 'medium' } }}>{t('ԿԱՊ')}</Typography></Link>
            </Box>
            {/* <Box sx={{marginLeft: 55, mt: 3}}>
                <a style={{marginLeft: 15}} href={`https://maps.app.goo.gl/Xd7s5TYjJKfWSYNP8`} ><ImageComponent sx={{width: 50}} src="images/location-icon.svg"></ImageComponent></a>
                <a style={{marginLeft: 15}} href="tel:+37498765432"><ImageComponent sx={{width: 50}} src="images/call-icon.svg"></ImageComponent></a>
                <a style={{marginLeft: 15}} href={`mailto:law@gmail.com`}><ImageComponent sx={{width: 50}} src="images/mail-icon.svg"></ImageComponent></a>

            </Box> */}

            {/* <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 53.5, mt: 3 }}>
                <a href="tel:+37498765432"><ImageComponent sx={{ width: 45 }} src="images/call-icon.svg"></ImageComponent></a>
                <a href="tel:+37498765432" style={{ fontFamily: theme_.fontType, fontSize: 'large', marginLeft: 14, color: "white", textDecoration: 'none' }}>+37498765432</a>
            </Box> */}
        </NavContainer>


        {/* <Box sx={{ position: 'absolute', top: '55px', right: '800px', display: 'flex', alignItems: 'center', marginLeft: 53.5, mb: 3, mt: -3 }}>
            <a href="tel:+37498765432"><ImageComponent sx={{ width: 45 }} src="images/call-icon.svg"></ImageComponent></a>
            <a href="tel:+37498765432" style={{ fontFamily: theme_.fontType, fontSize: 'large', marginLeft: 14, color: "white", textDecoration: 'none' }}>+37498765432</a>
        </Box> */}

        {/* <NavContainer>
            <PageNavItem tag='about' title='ABOUT US' href='/about' current={props.current} show={true} />
            <PageNavItem tag='products' title='PRODCUTS & SERVICES' href='/products' current={props.current} show={true} />
            <PageNavItem tag='support' title='SUPPORT' href='/support' current={props.current} show={true} />
            <PageNavItem tag='careers' title='CAREERS' href='/careers' current={props.current} show={true} />
            <PageNavItem tag='contact' title='CONTACT' href='/contact' current={props.current} show={true} />
        </NavContainer> */}







        <Dialog open={open} onClose={handleClose} PaperProps={{
            sx: {
                minWidth: 500, maxWidth: 900,
                // background: `linear-gradient(to right, #868f96 0%, #596164 100%)`,
                background: `linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%)`,
            }
        }}>
            <DialogTitle sx={{ m: 0, p: 2, color: '#2f0707', fontWeight: 'bold' }} id="customized-dialog-title">
                ԿԱՊ
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ color: theme_.themeColor }} />
            </IconButton>
            <Divider sx={{ width: '100%', backgroundColor: theme_.themeColor }} />

            <DialogContent sx={{ display: 'grid', justifyContent: 'center', p: 5 }} >
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <a href="tel:+37443408800"><ImageComponent sx={{ width: 45 }} src="images/call-icon.svg"></ImageComponent></a>
                    <a href="tel:+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 23, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a href="https://api.whatsapp.com/send?phone=+37443408800"> <ImageComponent sx={{ width: 60 }} src="images/whatsapp.png"></ImageComponent></a>
                    <a href="https://api.whatsapp.com/send?phone=+37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 15, color: "#2f0707", textDecoration: 'none' }}>+(374) 43 408 800</a>
                </Box>


                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a href="viber://chat?number=37443408800"> <ImageComponent sx={{ width: 54 }} src="images/viber.png"></ImageComponent></a>
                    <a href="viber://chat?number=37443408800" style={{ fontFamily: theme_.fontType, fontSize: 22, marginLeft: 20, color: "#2f0707", textDecoration: 'none', marginTop: -5, }}>+(374) 43 408 800</a>
                </Box>

            </DialogContent>
        </Dialog>


    </>
}




























/*
<NavBarContainer expand="lg" variant="primary" >

<div className="content">
    <ul>
        <li ><a href="/">Home</a></li>
        {Session.instance.isLoggedIn && <li><a href="/scans">Scans</a></li>}

        {Session.instance.isAdmin && <li><a href="/admin">Admin</a></li>}
        {Session.instance.isDeveloper && <li><a href="/dev">Dev</a></li>}

        <li><a href="/contact">Contact Us</a></li>
    </ul>
</div>
</NavBarContainer>
*/
